// Function to initialize tooltip behavior
function HotspotImage() {
  // Add delay for Tridion JavaScript created hotspot images
  setTimeout(function () {
    // Select all elements that trigger tooltips
    const tooltips = document.querySelectorAll('.c-hotspot-image__button');

    // Add event listeners for each tooltip button
    tooltips.forEach((button) => {
      button.addEventListener('click', handleTooltipToggle);
    });

    // Add event listener for Escape key press
    document.addEventListener('keydown', handleKeyDown);

    // Add event listener for click outside tooltip
    document.addEventListener('click', handleClickOutside);
  }, 2000);
}

// Function to handle the tooltip toggle
function handleTooltipToggle(event) {
  // Prevent default action if needed
  event.preventDefault();

  const button = event.currentTarget;
  const tooltipId = button.getAttribute('aria-controls');
  const tooltip = document.getElementById(tooltipId);

  if (tooltip.classList.contains('c-hotspot-image__tooltip--open')) {
    closeTooltip(button, tooltip);
  } else {
    openTooltip(button, tooltip);
  }
}

// Function to open a tooltip
function openTooltip(button, tooltip) {
  // Add active class and make tooltip visible
  tooltip.classList.add('c-hotspot-image__tooltip--open');
  tooltip.setAttribute('aria-hidden', 'false');
  button.setAttribute('aria-expanded', 'true');

  // Add event listener for click outside
  document.addEventListener('click', handleClickOutside);
}

// Function to close a tooltip
function closeTooltip(button, tooltip) {
  // Remove active class and hide tooltip
  tooltip.classList.remove('c-hotspot-image__tooltip--open');
  tooltip.setAttribute('aria-hidden', 'true');
  button.setAttribute('aria-expanded', 'false');

  // Remove event listener for click outside
  document.removeEventListener('click', handleClickOutside);
}

// Function to handle Escape key press
function handleKeyDown(event) {
  if (event.key === 'Escape') {
    const activeTooltip = document.querySelector(
      '.c-hotspot-image__tooltip--open'
    );
    if (activeTooltip) {
      const button = document.querySelector(
        `[aria-controls="${activeTooltip.id}"]`
      );
      closeTooltip(button, activeTooltip);
    }
  }
}

// Function to handle click outside the tooltip
function handleClickOutside(event) {
  const activeTooltip = document.querySelector(
    '.c-hotspot-image__tooltip--open'
  );
  if (
    activeTooltip &&
    !activeTooltip.contains(event.target) &&
    !event.target.closest('.c-hotspot-image__button')
  ) {
    const button = document.querySelector(
      `[aria-controls="${activeTooltip.id}"]`
    );
    closeTooltip(button, activeTooltip);
  }
}

export default HotspotImage;
