import { range, shuffle, userPrefersReducedMotion } from './utilities';

function HeroPattern() {
  let time = 0;

  const c = document.querySelector('.c-hero__canvas');
  if (!c) return;
  const ctx = c.getContext("2d");
  
  const sineColour = "rgb(19,170,19)";
  const reduceMotion = userPrefersReducedMotion();

  const crossGap = 147;
  const minimumOpacity = 0;
  const maximumOpacity = 0.5;
  const crossSpeed = 0.25;

  let width;
  let height;
  let across;
  let down;
  let crosses = [];
  let totalPoints;

  function draw() {
    // Sine wave
    time = time + 0.005;
    
    ctx.clearRect(0, 0, c.width, c.height);
    ctx.beginPath();
  
    for(let cnt = c.width; cnt > 0; cnt--) {
      ctx.lineTo(cnt, c.height * 0.5 - (Math.cos(time + cnt / 5 * 0.03) * (height * 0.3) ));
    }
  
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = sineColour;
    ctx.stroke();
  
    // Crosses
    crosses.forEach(cross => {
      drawCross(cross.x, cross.y, range(0, crosses.length, minimumOpacity, maximumOpacity, cross.counter));
  
      cross.counter += cross.direction;
      if (cross.counter >= crosses.length) cross.direction = -1 * crossSpeed;
      if (cross.counter < 0) cross.direction = crossSpeed;
    })
  
    if (!reduceMotion) {
      window.requestAnimationFrame(draw);
    }
  }
  
  function onResize() {
    width = c.scrollWidth;
    height = c.scrollHeight;
  
    ctx.canvas.width  = width;
    ctx.canvas.height = height;
  
    across = Math.ceil(width / crossGap);
    down = Math.ceil(height / crossGap);
  
    totalPoints = (across + 1) * (down + 1);
    const ids = shuffle(Array.from({ length: totalPoints }, (_, i) => (i)))
    crosses = [];
    for(let x = 0; x <= across; x++) {
      for(let y = 0; y <= down; y++) {
        crosses.push({
          counter: ids.shift(),
          direction: Math.random() > 0.5 ? crossSpeed : -1 * crossSpeed,
          x: crossGap * (x + 1) - crossGap / 1,
          y: crossGap * (y + 1) - crossGap / 2
        });
      }
    }
  }
  
  function drawCross(x, y, opacity) {
    ctx.beginPath();
    ctx.moveTo(x - 14, y);
    ctx.lineTo(x + 14, y);
    ctx.strokeStyle = `rgba(255, 255, 255, ${opacity}`;
    ctx.moveTo(x, y - 14);
    ctx.lineTo(x, y + 14);
    ctx.stroke();
  }

  window.addEventListener('resize', onResize);
  onResize();
  draw();
}

export default HeroPattern;
