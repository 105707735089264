import Accordion from './imports/accordion';
import Carousel from './imports/carousel';
import ClearForm from './imports/clear-form';
import Comparison from './imports/comparison';
import Facet from './imports/facet';
import Gallery from './imports/gallery';
import HeroPattern from './imports/hero-pattern';
import HeroVideo from './imports/hero-video';
import HotspotImage from './imports/hotspot-image';
import InternalNav from './imports/internal-nav';
import OffCanvas from './imports/off-canvas';
import ResponsiveTable from './imports/responsive-table';
import ScrollbarWidth from './imports/scrollbar-width';
import ScrollSpy from './imports/scroll-spy';
import Tabs from './imports/tabs';

(() => {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
  }

  Accordion();
  Carousel();
  ClearForm();
  Comparison();
  Facet();
  Gallery();
  HeroPattern();
  HeroVideo();
  HotspotImage();
  InternalNav();
  OffCanvas();
  ResponsiveTable();
  ScrollbarWidth();
  ScrollSpy();
  Tabs();
})();
