function ClearForm() {
  const formClear = document.getElementById('clearForm');
  if (formClear) {
    formClear.addEventListener('click', function () {
      const fields = this.closest('form').elements;
      fields.forEach(field => {
        if (field.value != '') {
          if (
            field.type.toLowerCase() == 'text' || 
            field.type.toLowerCase() == 'email' || 
            field.type.toLowerCase() == 'tel' || 
            field.type.toLowerCase() == 'select-one' || 
            field.type.toLowerCase() == 'textarea'
            ) {
            field.value = '';
            return;
          } else if (field.type.toLowerCase() == 'checkbox') {
            field.checked = false;
            return;
          }
        }
      });
    });
  };
}

export default ClearForm;