function ScrollSpy() {
  const internalNav = document.querySelector('.c-internal-nav--sticky');
  const internalNavHeight = internalNav ? internalNav.offsetHeight : 0;

  const spies = Array.from(document.querySelectorAll('.c-scroll-spy'));
  spies.forEach(spy => {
    setup(spy, internalNavHeight);
  });
}

function setup(element, internalNavHeight) {
  const anchors = Array.from(element.querySelectorAll('a'));
  const observer = new IntersectionObserver(observation(anchors));
  
  anchors.forEach(anchor => {
    const id = anchor.getAttribute('href').replace('#', '');
    const target = document.getElementById(id);
    if (!target) return;

    observer.observe(target);
  });

  const currentTop = parseFloat(window.getComputedStyle(element).top);
  element.style.top = `${currentTop + internalNavHeight}px`;
}

/**
 * @param {Array<HTMLAnchorElement>} results 
 * @return {Array<IntersectionObserverEntry>}
 */
function observation(anchors) {
  return (results) => {
    const firstObservation = results.find(x => x.isIntersecting);

    if (firstObservation) {
      anchors.forEach(x => {
        x.classList.toggle(
          'c-scroll-spy-active',
          x.getAttribute('href') === '#' + firstObservation.target.id
        )
      })
    }
  }
}

export default ScrollSpy;
