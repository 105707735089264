function Facet() {
    const facetAccordions = Array.from(document.querySelectorAll('.c-facet__toggle'));
    facetAccordions.forEach(setupFacetAccordions);
}

function setupFacetAccordions(toggle) {
    toggle.addEventListener('click', titleClick())
}

function titleClick() {
    return (event) => {
        event.preventDefault();
        
        var toggle = event.currentTarget;
        var facet = toggle.parentElement;

        facet.classList.toggle('c-facet--open');
        const expanded = facet.classList.contains('c-facet--open') ? 'true' : 'false';
        toggle.setAttribute('aria-expanded', expanded);
    }
}

export default Facet;
