function Comparison() {
  document.querySelectorAll('.c-comparison-table').forEach(extractMobileComparison)
}

function extractMobileComparison(container) {
  const parent = container.parentNode.parentNode;
  const body = parent.querySelector('.c-comparison-table tbody');
  const head = parent.querySelector('.c-comparison-table thead');
  const mobile = parent.parentElement.querySelector('.c-comparison-table__mobile');

  if (!body || !head || !mobile) return;

  // Remove empty corner piece  
  const productsInfo = Array.from(head.children[0].children).filter((x, i) => i).map(x => ({ info: x.innerHTML }));
  const productsButtons = Array.from(head.children[1].children).filter((x, i) => i).map(x => ({ buttons: x.innerHTML }));
  const products = productsInfo.map((info, index) => ({ ...info, ...productsButtons[index], table: [] }));

  Array.from(body.children).forEach(row => {
    // For table row headers, push to all products
    if (row.classList.contains('c-comparison-table__title')) {
      products.forEach(x => {
        x.table.push({ type: 'title', title: row.children[0].textContent })
      })
    } else {
      // For all others, loop the cells and push to their appropriate parent
      const cells = Array.from(row.children);
      const firstCell = cells.shift();

      cells.forEach((el, index) => {
        products[index].table.push({
          type: 'row',
          key: firstCell.textContent,
          value: el.innerHTML
        })
      })
    }
  })

  products.forEach(product => {
    const el = createMobileProduct(product);
    mobile.appendChild(el)
  })
}

function createMobileProduct(product) {
  const parent = document.createElement('article');
  parent.innerHTML = `${product.info}${product.buttons}
  <table class="c-comparison-table">
    ${product.table.map(x => {
      if (x.type === 'title') {
        return `<tr class="c-comparison-table__title"><th><span>${x.title}</span></th><th></th></tr>`;
      } else {
        return `<tr>
          <td>${x.key}</td>
          <td>${x.value}</td>
        </tr>`;
      }
    }).join('')}
  </table>`;

  return parent;
}

export default Comparison;
