function InternalNav() {
    const internalNav = document.querySelector('.c-internal-nav--sticky');

    if (!internalNav) {
        return;
    }

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
    };

    const intersectionCallback = (entries, observer) => {
        const isIntersecting = entries[0].isIntersecting;
        internalNav.classList.toggle('c-internal-nav--stuck', !isIntersecting);
    };

    const observer = new IntersectionObserver(intersectionCallback, observerOptions);
    observer.observe(internalNav);

    let internalNavHeight = internalNav.offsetHeight;

    const updateInternalNavHeight = () => {
        internalNavHeight = internalNav.offsetHeight;
        const anchors = Array.from(internalNav.querySelectorAll('a'));
        anchors.forEach(anchor => {
            const id = anchor.getAttribute('href').replace('#', '');
            const target = document.getElementById(id);
            if (target) {
                target.style.scrollMarginTop = `${internalNavHeight}px`;
            }
        });
    };

    window.addEventListener('resize', updateInternalNavHeight);

    updateInternalNavHeight();
}

export default InternalNav;