function Gallery() {
  const mainSlides = document.querySelectorAll('.c-gallery__main .c-gallery__slide');
  const thumbnailSlides = document.querySelectorAll('.c-gallery__thumbnails .c-gallery__slide');

  thumbnailSlides.forEach(thumbnailSlide => thumbnailSlide.addEventListener('click', function(e) {
    thumbnailSlides.forEach(function(thumbnail) {
      if(thumbnail != e.target.parentNode) {
        thumbnail.classList.remove('active');
      }
    });
    e.target.parentNode.classList.add('active');

    mainSlides.forEach(function(mainSlide) {
      if(mainSlide.dataset.slide == thumbnailSlide.dataset.slide) {
        mainSlide.classList.add('active');
      } else {
        mainSlide.classList.remove('active');
      }
    });
  }));

  mainSlides.forEach(function(mainSlide) {
    if(!mainSlide.classList.contains('c-gallery__video')) {
      mainSlide.addEventListener('click', lightboxTrigger);
    }
  });

  function lightboxTrigger() {
    const lightbox = document.querySelector('.c-gallery__main').cloneNode(true);
    lightbox.classList.remove('c-gallery__main');
    lightbox.classList.add('c-lightbox');
    lightbox.querySelectorAll('.c-gallery__slide').forEach(function(slide) {
      if(slide.classList.contains('c-gallery__video')) {
        slide.remove();
      }
      if(!slide.classList.contains('c-gallery__video')) {
        var slideImg = slide.querySelector('img');
        if(slideImg.srcset != '') {
          slideImg.removeAttribute('srcset');
        }
        var slideImgSrc = slideImg.getAttribute('src');
        if (slideImgSrc) {
          if (slideImgSrc.indexOf('?') !== -1) {
            slideImg.setAttribute('src', slideImgSrc.split("?")[0] + '?quality=90&width=2176');
          } else {
            slideImg.setAttribute('src', slideImgSrc + '?quality=90&width=2176');
          }
        } else {
          console.log(slideImg);
          console.log('has no src defined...');
        }
      }
      slide.classList.add('c-lightbox__slide');
      slide.classList.remove('c-gallery__slide');
    });
    document.body.appendChild(lightbox);
    lightbox.insertAdjacentHTML('beforeend', '<a class="c-lightbox__prev"></a><a class="c-lightbox__next"></a>');

    lightbox.querySelector('.c-lightbox__prev').addEventListener('click', function() {
      const lightboxActive = lightbox.querySelector('.active');
      const prevImage = lightbox.querySelector('[data-slide="' + (parseInt(lightboxActive.dataset.slide) - 1) + '"]');
      if(prevImage != null) {
        lightboxActive.classList.remove('active');
        prevImage.classList.add('active');
      }
    });

    lightbox.querySelector('.c-lightbox__next').addEventListener('click', function() {
      const lightboxActive = lightbox.querySelector('.active');
      const nextImage = lightbox.querySelector('[data-slide="' + (parseInt(lightboxActive.dataset.slide) + 1) + '"]');
      if(nextImage != null) {
        lightboxActive.classList.remove('active');
        nextImage.classList.add('active');
      }
    });

    lightbox.addEventListener('click', function(e) {
      if (e.target !== e.currentTarget) {
        return;
      } else {
        lightbox.remove();
      }
    });
  }
}

export default Gallery;
