function HeroVideo() {
  document.addEventListener("DOMContentLoaded", () => {
    const videoBackground = document.getElementById('videoBackground');
    if(typeof(videoBackground) != 'undefined' && videoBackground != null) {
      const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
      if(!reducedMotion || reducedMotion.matches) {
        videoBackground.pause();
      }
      
      const lightboxTrigger = document.getElementById('playVideo');
      const lightboxContainer = document.getElementById('lightboxVideo');
      const lightboxVideo = lightboxContainer.querySelector('.c-lightbox__video');

      lightboxTrigger.addEventListener('click', e => {
        videoBackground.pause();
        lightboxContainer.classList.remove('u-hidden');
        checkVideos();
      });
  
      lightboxContainer.addEventListener('click', (e) => {
        if (!e.target.classList.contains('c-lightbox__video')) {
          videoBackground.setAttribute('autoplay','');
          lightboxContainer.classList.add('u-hidden');
          checkVideos();
        }
      });
      window.addEventListener('keydown', function(e) {
        if (e.key == "Escape") {
          videoBackground.setAttribute('autoplay','');
          lightboxContainer.classList.add('u-hidden');
          checkVideos();
        }
      });

      function checkVideos() {
        if(lightboxContainer.classList.contains('u-hidden')) {
          if (videoBackground.paused) {
            videoBackground.play();
          }
          if (!lightboxVideo.paused) {
            lightboxVideo.pause();
          }
        } else {
          if (!videoBackground.paused) {
            videoBackground.pause();
          }
        }
      }
    }
  });
}

export default HeroVideo;